import classes from "./PlusMinusButton.module.css";

type Props = {
  onToggle: (value: boolean) => void;
  active: boolean;
};

const PlusMinusButton = (props: Props) => {
  const toggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onToggle(!props.active);
  };

  return (
    <div className={classes.plusminus}>
      <div
        className={props.active ? "active" : undefined}
        onClick={toggle}
      ></div>
    </div>
  );
};

export default PlusMinusButton;
