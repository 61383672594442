import { Center, Text } from "@mantine/core";
import classes from "./About.module.css";
import { FaFileDownload } from "react-icons/fa";
import { ResumePDf } from "src/assets";

const About = () => {
  return (
    <div className={classes["about-wrapper"]}>
      <Center style={{ flexDirection: "column" }}>
        <div className={classes.headshot} />
        <a
          className={classes["resume-dowload-wrapper"]}
          href={ResumePDf}
          download
        >
          <FaFileDownload className={classes["resume-download-icon"]} />
          <div className={classes["resume-text"]}>
            {"Download my R\u00E9sum\u00E9"}
          </div>
        </a>
        <div className={classes["title-wrapper"]}>
          <Text className={"title-desktop"}>
            Hello, my name is <b>Andrew Tanaka</b>, a Software Engineer
            specializing in product delivery, systems architecture, and
            orchestration of development teams both international and domestic.
            I've worked with a variety of technologies and frameworks over the
            years including
            <b> React, Node.js</b>, <b>Azure Cloud </b>
            services, and <b>SQL</b> environments. <br />
            <br /> I enjoy creating collaborative and positive team environments
            where everyone feels challenged, empowered, and valued. Outside of work, you'll
            likely find me rock climbing, biking, making music, or playing some 5th Edition. 
            Welcome to my personal site, where you can explore my professional journey and some
            of my recent projects.
          </Text>
          <Text className={"title-mobile"}>
            Hello, I'm <b>Andrew Tanaka</b>, a Software Engineer specializing in
            product delivery, systems architecture, and orchestration of
            development teams. I've worked with a variety of tech over the years
            including <b> React, Node.js</b>, <b>Azure Cloud </b>services, and{" "}
            <b>SQL</b> environments. <br />
            <br /> I enjoy creating collaborative and positive team environments
            where everyone feels empowered and valued. Outside of work, you'll
            likely find me rock climbing, biking, or jamming with friends.
            Welcome to my personal site, where you can explore my professional
            journey.
          </Text>
        </div>
      </Center>
    </div>
  );
};

export default About;
