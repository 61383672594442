import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  Button,
  Text,
  MantineProvider,
  MantineThemeOverride,
  MantineTheme,
  virtualColor,
} from "@mantine/core";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";

import { EnvConfig, GetEnv } from "./utils/EnvConfig";
import AppProvider from "./api/AppContext";
import Router from "./navigation/";
import { Notifications } from "@mantine/notifications";
import { generateTextMantineColor } from "./utils/theme";
import { LoaderOverlayProvider } from "./api/context/LoaderOverlayContext ";
import { COLORS } from "./utils/colors";

const env = GetEnv() as EnvConfig;

const MessageModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  modalBody: string;
  innerText?: string;
  btnText?: string;
  onCancel?: () => void;
}>) => (
  <>
    <Text size="sm">{innerProps.innerText}</Text>
    <Button
      fullWidth
      mt="md"
      onClick={() =>
        innerProps.onCancel ? innerProps.onCancel() : context.closeModal(id)
      }
    >
      {innerProps.btnText || `Close`}
    </Button>
  </>
);

interface Props {
  children: React.ReactNode;
}
const MantineProviders: FC<Props> = (
  { children },
  theme: MantineThemeOverride | undefined
) => {
  return (
    <MantineProvider
      theme={{
        ...theme,
        components: {
          Badge: {
            styles: (theme: MantineTheme) => ({
              root: {
                color: `${theme.colors.secondaryText[4]} !important`,
                backgroundColor: `${theme.colors.primary[8]} !important`,
              },
            }),
          },
          Button: {
            styles: (theme: MantineTheme) => ({
              inner: {
                color: `${theme.colors.primaryText[4]} !important`,
              },
            }),
          },

          InputWrapper: {
            styles: (theme: MantineTheme) => ({
              root: {
                position: "relative",
                "&:focus-within": {
                  label: {
                    transform: "translateY(-20px)",
                    "&::before": {
                      opacity: 1,
                    },
                  },
                },
                "&:disabled": {
                  label: {
                    color: "red !important",
                  },
                },
              },
              label: {
                fontSize: 11,
                position: "absolute",
                top: 10,
                left: 10,
                display: "block",
                transition: "0.15s",
                background: "transparent",
                zIndex: 1,
                padding: "0 3px",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  width: "100%",
                  left: 1,
                  height: 8,
                  zIndex: -1,
                  bottom: 0,
                },
              },
            }),
          },
          Input: {
            styles: (theme: MantineTheme) => ({
              input: {
                borderColor: theme.colors.secondary[0],
                "&:focus": {
                  borderColor: theme.colors.primary[6],
                },
                ":disabled": {
                  color: theme.colors.secondaryText[4],
                },
              },
            }),
          },
          Modal: {
            styles: (theme: MantineTheme) => ({
              inner: {
                ".mantine-Modal-modal": {
                  padding: 0,
                },
              },

              header: {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
                color: theme.colors.primaryText[6],
                backgroundColor: theme.colors.primary[6],
                margin: 0,
                padding: 10,
                borderBottom: `1px solid ${theme.colors.secondary[0]}`,
                ".mantine-Modal-close": {
                  color: theme.colors.primaryText[6],
                  "&:hover": {
                    backgroundColor: theme.colors.primary[4],
                  },
                },
              },
              title: {
                flex: 1,
                fontWeight: 500,
                fontSize: 20,
                textAlign: "center",
                marginRight: 0,
                marginLeft: 28,
                color: theme.colors.primaryText[4],
              },
              body: {
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                backgroundColor: theme.colors.secondary[4],
                color: theme.colors.secondaryText[4],
                padding: 20,
                ".mantine-Paper-root ": {
                  overflow: "inherit",
                },
                ".mantine-Group-root": {
                  button: {
                    flex: 1,
                  },
                },
              },
              root: {
                size: "min(450px, 80vw)",
              },
              modal: {
                backgroundColor: "rgba(0,0,0,0)",
              },
            }),
          },
          Text: {
            styles: (theme: MantineTheme) => ({
              root: {
                color: theme.colors.secondaryText[5],
              },
            }),
          },
        },
        colors: {
          greenMachine: COLORS.greenMachine,
          greenMachineText: generateTextMantineColor(COLORS.greenMachine, true),
          blueOxford: COLORS.blueOxford,
          blueOxfordText: generateTextMantineColor(COLORS.blueOxford, true),
          charcoal: COLORS.charcoal,
          charcoalText: generateTextMantineColor(COLORS.charcoal, true),
          light: COLORS.light,
          lightText: generateTextMantineColor(COLORS.light, true),
          dark: COLORS.dark,
          darkText: generateTextMantineColor(COLORS.dark, true),
          primary: virtualColor({
            name: "primary",
            light: "blueOxford",
            dark: "yellow",
          }),
          secondary: virtualColor({
            name: "secondary",
            light: "light",
            dark: "dark",
          }),
          primaryText: virtualColor({
            name: "primaryText",
            light: "blueOxfordText",
            dark: "greenMachineText",
          }),
          secondaryText: virtualColor({
            name: "secondaryText",
            light: "lightText",
            dark: "darkText",
          }),
        },
        primaryColor: "primary",
        primaryShade: { light: 2, dark: 5 },
        black: "#1b1b1b",
      }}
      defaultColorScheme="dark"
    >
      <Notifications />
      <ModalsProvider modals={{ message: MessageModal }}>
        {children}
      </ModalsProvider>
    </MantineProvider>
  );
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <MantineProviders>
          <AppProvider env={env}>
            <LoaderOverlayProvider>
              <Router />
            </LoaderOverlayProvider>
          </AppProvider>
        </MantineProviders>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
