export const PROJECT_SLIDE_CONTENT_MEDICAL = `This med space project served as both an interface and conduit
between medical professionals, healthcare consumers, insurance
claim reviewers, and cloud-based medical records. It pushed
the boundary of our technological capabilities, encompassing
13 applications and micro-services to provide comprehensive
coverage of requirements. The project involved rigorous
critique to ensure compliance with local, state, and federal
policies and regulations concerning medical documents and
information transfer. The initial implementation in several
urgent care facilities has yielded positive results.`;

export const PROJECT_SLIDE_TECH_MEDICAL = `Node \u00B7 React \u00B7 React-Native \u00B7 Redux \u00B7 Prisma \u00B7 Nexus \u00B7 MongoDB \u00B7 C# \u00B7 .NET \u00B7 SQL Server \u00B7 Sendgrid \u00B7 Firebase \u00B7 Twilio \u00B7 Gravity \u00B7 HL7 FHIR \u00B7 AWS \u00B7 Azure Cloud`;
