import { Collapse, Group, Text, Timeline } from "@mantine/core";
import { useState } from "react";
import classes from "./Education.module.css";
import PlusMinusButton from "src/components/PlusMinusButton";
import { useMediaQuery } from "@mantine/hooks";

type Props = {
  alwaysOpen?: boolean;
};

const Education = ({ alwaysOpen }: Props) => {
  const [target, setTarget] = useState<number | undefined>();
  const mobile = useMediaQuery("(max-width: 46em");

  const toggle = (item: number) => {
    if (target === item) {
      setTarget(undefined);
    } else {
      setTarget(item);
    }
  };

  return (
    <div className={classes["education-wrapper"]}>
      <Timeline className={classes.timeline} bulletSize={"3em"} lineWidth={4}>
        <Timeline.Item
          title={
            <Group
              align="flex-start"
              gap={"sm"}
              justify="space-between"
              onClick={() => toggle(0)}
            >
              <Text>{"B.S. Computer Science"}</Text>
              {!alwaysOpen && !mobile && (
                <PlusMinusButton
                  active={target === 0}
                  onToggle={(value) =>
                    value ? setTarget(0) : setTarget(undefined)
                  }
                />
              )}
            </Group>
          }
          bullet={
            <div className={classes["education-icon-unc"]}>
              <div />
            </div>
          }
        >
          <Text
            className={classes.subtext}
            size="sm"
          >{`UNC Chapel Hill | 2020`}</Text>
          <Collapse
            in={mobile || alwaysOpen || target === 0}
            className={classes["collapse-section"]}
          >
            <div>
              <div>
                {`I had the pleasure of attending the University of North Carolina in the beautiful town of Chapel Hill, where I earned a B.S. in Computer Science. My studies focused on algorithms, networks, and graphical processing. Alongside my major, I pursued a minor in Mathematics, where I discovered a particular passion for Topology.`}
              </div>
              <div>{`During my time at UNC, I was deeply involved in supporting the student community. I served as a Residential Advisor and actively participated in Community Government, which led to receiving the Lt. Governor of the Year award in 2016 for my commitment to community engagement.`}</div>
              <div>{`Beyond academics and community service, I developed interests in cultural and historical Asian studies, longboarding, and music, which have remained continued avenues for personal enrichment.`}</div>
            </div>
          </Collapse>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default Education;
