import { Center } from "@mantine/core";
import { logo } from "src/assets/index";
import anime from "animejs";
import classes from "./SignatureLogo.module.css";
import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "src/api/AppContext";

type Props = {
  skipAnim?: boolean;
  repeat?: boolean;
};

const SignatureLogo = ({ skipAnim = false, repeat = false }: Props) => {
  const { animTrigger } = useContext(AppContext);

  const animateSignature = useCallback(() => {
    anime({
      targets: ".signature-path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInSine",
      duration: 3000,
      delay: function (el, i) {
        return i * 250;
      },
      direction: repeat ? "alternate" : "forward",
      loop: repeat,
    });
  }, [repeat]);

  useEffect(() => {
    if (!skipAnim && animTrigger === undefined) {
      animateSignature();
    }
  }, [skipAnim, animateSignature, animTrigger]);

  return <Center className={classes["signature-wrapper"]}>{logo}</Center>;
};

export default SignatureLogo;
