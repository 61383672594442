import { Group, Rating } from "@mantine/core";
import classes from "./SkillRating.module.css";

type Props = {
  name: string;
  value: number;
};

const SkillRating = ({ value, name }: Props) => {
  return (
    <Group justify="space-between" className={classes["rating-wrapper"]}>
      <div className={classes["rating-label"]}>{name}</div>
      <Rating value={value} readOnly />
    </Group>
  );
};

export default SkillRating;
