import React, { createContext, useContext, useState, FC } from "react";
import { Overlay, Loader, Stack, Center } from "@mantine/core";

interface LoaderOverlayContextType {
  isLoading: boolean;
  showOverlayLoader: () => void;
  hideOverlayLoader: () => void;
  LoaderOverlay: FC;
}

const LoaderOverlayContext = createContext<
  LoaderOverlayContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

export const LoaderOverlayProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showOverlayLoader = () => setIsLoading(true);
  const hideOverlayLoader = () => setIsLoading(false);

  const LoaderOverlay: FC = () => {
    return (
      <>
        {isLoading && (
          <Overlay
            zIndex={1000}
            opacity={0.7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "75vh",
            }}
          >
            <Stack>
              <Center>
                <Loader size={56} />
              </Center>
            </Stack>
          </Overlay>
        )}
      </>
    );
  };

  return (
    <LoaderOverlayContext.Provider
      value={{ isLoading, showOverlayLoader, hideOverlayLoader, LoaderOverlay }}
    >
      {children}
    </LoaderOverlayContext.Provider>
  );
};

export const useLoaderOverlay = (): LoaderOverlayContextType => {
  const context = useContext(LoaderOverlayContext);
  if (context === undefined) {
    throw new Error(
      "useLoaderOverlay must be used within a LoaderOverlayProvider"
    );
  }
  return context;
};
