export type EnvConfig = {
  env: "dev" | "qa" | "prod" | "staging" | "dev-qa" | "dev-staging";
  uri: string;
  wsUri: string;
  appUrl: string;
};

export const GetEnv = () => {
  try {
    const env: Partial<EnvConfig> = {
      env: process.env.REACT_APP_ENV as
        | "dev"
        | "qa"
        | "prod"
        | "staging"
        | "dev-qa"
        | "dev-staging",
      // uri: process.env.REACT_APP_BASE_URL,
      // wsUri: process.env.REACT_APP_WS_URL,
    };
    if (
      Object.keys(env).find((key) => env[key as keyof typeof env] === undefined)
    ) {
      throw new Error(
        `Env values are undefined: ${JSON.stringify(
          Object.keys(env).filter(
            (key) => env[key as keyof typeof env] === undefined
          )
        )}`
      );
    } else {
      return env;
    }
  } catch (e) {
    alert(`ENV Error: ${e}`);
  }
};
