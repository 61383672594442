import { useRoutes, useLocation } from "react-router-dom";
import Home from "src/screens/Home";
import { AnimatePresence } from "framer-motion";
import React from "react";

const NoAuthRoutes = () => {
  const screen = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
  ]);

  const location = useLocation();

  if (!screen) return null;

  return (
    <AnimatePresence mode="wait">
      {React.cloneElement(screen, { key: location.pathname })}
    </AnimatePresence>
  );
};

export default NoAuthRoutes;
