import { IMantineColor } from "./theme";

export const COLORS: Record<string, IMantineColor> = {
  greenMachine: [
    "#EEFCF2",
    "#C4F1D2",
    "#A2E4B6",
    "#85D79E",
    "#6EC989",
    "#5ABB77",
    "#4DAB69",
    "#409B5B",
    "#348C4E",
    "#2A7F43",
  ],

  blueOxford: [
    "#00104F",
    "#000E47",
    "#000C3F",
    "#000B38",
    "#000A32",
    "#00092D",
    "#000926",
    "#000723",
    "#00061F",
    "#00051B",
  ],

  charcoal: [
    "#363636",
    "#303030",
    "#2A2A2A",
    "#262626",
    "#222222",
    "#1E1E1E",
    "#1B1B1B",
    "#181818",
    "#151515",
    "#121212",
  ],

  light: [
    "#fff",
    "#f8f8f8",
    "#f2f2f2",
    "#ddd",
    "#d1d1d1",
    "#bfbfbf",
    "#B3B3B3",
    "#ADADAD",
    "#9a9a9a",
    "#777",
  ],

  dark: [
    "#4d4d4d",
    "#404040",
    "#3f3f3f",
    "#3b3b3b",
    "#303030",
    "#2e2e2e",
    "#222222",
    "#1e1e1e",
    "#181818",
    "#121212",
  ],
};
