import { FC } from "react";
import NoAuthRoutes from "./NoAuthRoutes";
import { HeaderContentProvider } from "src/api/context/HeaderContext";
import AppShell from "src/modules/AppShell";
import { useLoaderOverlay } from "src/api/context/LoaderOverlayContext ";

const Router: FC = () => {
  const { LoaderOverlay } = useLoaderOverlay();

  return (
    <>
      <LoaderOverlay />
      <HeaderContentProvider>
        <AppShell>
          <NoAuthRoutes />
        </AppShell>
      </HeaderContentProvider>
    </>
  );
};

export default Router;
