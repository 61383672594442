import { createContext, useContext, ReactNode, useState } from "react";

interface HeaderContextType {
  headerRightContent: ReactNode | null;
  updateHeaderContent: (content: ReactNode) => void;
  isBackIcon: boolean;
  setisBackIcon: (isBack: boolean) => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error(
      "useHeaderContent must be used within a HeaderContentProvider"
    );
  }
  return context;
};

interface HeaderContentProviderProps {
  children: ReactNode;
}

export const HeaderContentProvider: React.FC<HeaderContentProviderProps> = ({
  children,
}) => {
  const [headerRightContent, setHeaderRightContent] =
    useState<ReactNode | null>(null);
  const [isBackIcon, setisBackIcon] = useState(false);

  const updateHeaderContent = (content: ReactNode) => {
    setHeaderRightContent(content);
  };

  return (
    <HeaderContext.Provider
      value={{
        headerRightContent,
        updateHeaderContent,
        isBackIcon,
        setisBackIcon,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
