import { memo, useContext } from "react";
import {
  AppShell,
  useMantineTheme,
  MantineSize,
  useMantineColorScheme,
  MantinePrimaryShade,
  Grid,
} from "@mantine/core";
import classes from "./Header.module.css";
import { useViewportSize } from "@mantine/hooks";
import { motion, useScroll, useTransform } from "framer-motion";
import SignatureLogo from "src/modules/SignatureLogo";
import { AppContext } from "src/api/AppContext";
import anime from "animejs";

interface HeaderProps {
  mobileOpened: boolean;
  desktopOpened: boolean;
  toggleMobile: () => void;
  toggleDesktop: () => void;
  navbarBreakpoint?: MantineSize | undefined;
}

const Header = (props: HeaderProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { reloadAnims } = useContext(AppContext);

  const { height } = useViewportSize();
  const { scrollY } = useScroll();
  const opacity = useTransform(
    scrollY,
    [0, height / 2 - 37, height / 2 - 36],
    [0, 0, 1]
  );

  return (
    <motion.div style={{ opacity, zIndex: 20 }}>
      <AppShell.Header
        px={15}
        pt={15}
        className={classes.header}
        style={{
          backgroundColor:
            theme.colors.secondary[
              colorScheme === "light"
                ? (theme.primaryShade as MantinePrimaryShade).light
                : (theme.primaryShade as MantinePrimaryShade).dark
            ],
        }}
      >
        <Grid h="100%" px="md">
          <Grid.Col span={2}></Grid.Col>
          <Grid.Col
            span={8}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <motion.div
              style={{ opacity: opacity }}
              onClick={() => {
                window.scrollTo(0, 0);
                let target = document.getElementById("page-indicator");
                if (target) {
                  target.style.opacity = "0";
                }
                anime.remove([".signature-path", "#page-indicator"]);
                reloadAnims();
              }}
              className={classes["logo-wrapper"]}
            >
              <SignatureLogo />
            </motion.div>
          </Grid.Col>
          <Grid.Col
            span={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          ></Grid.Col>
        </Grid>
      </AppShell.Header>
    </motion.div>
  );
};

export default memo(Header);
