import Signature from "../../modules/Signature";
import SignatureLogo from "../../modules/SignatureLogo";
import classes from "./Home.module.css";
import { motion, useScroll, useTransform } from "framer-motion";
import { useViewportSize } from "@mantine/hooks";
import About from "src/modules/About";
import Experience from "src/modules/Experience";
import { FaAnglesDown } from "react-icons/fa6";
import SectionHeader from "src/components/SectionHeader";
import SectionDivider from "src/components/SectionDivider";
import anime from "animejs";
import { useContext, useEffect } from "react";
import Education from "src/modules/Education";
import Skills from "src/modules/Skills";
import Projects from "src/modules/Projects";
import { AppContext } from "src/api/AppContext";

const Home = () => {
  const { height, width } = useViewportSize();
  const { scrollY } = useScroll();
  const { animTrigger } = useContext(AppContext);

  const sigWidth = useTransform(
    scrollY,
    [0, height / 2 - 75],
    [width - 15, 150],
    {
      clamp: true,
    }
  );

  const opacity = useTransform(scrollY, [0, 1], [1, 0]);

  const opacityLogo = useTransform(
    scrollY,
    [0, 1, height / 2 - 37, height / 2 - 36],
    [0, 1, 1, 0]
  );

  const animateDDArrow = () => {
    anime({
      targets: "#page-indicator",
      opacity: [0, 1],
      easing: "easeInOutSine",
      duration: 800,
      direction: "alternate",
      loop: true,
    });
  };

  useEffect(() => {
    if (animTrigger === undefined) {
      setTimeout(() => {
        animateDDArrow();
      }, 3200);
    }
  }, [animTrigger]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.6 } }}
      exit={{ opacity: 0, transition: { duration: 0.6 } }}
    >
      <div className={classes["sig-section"]}>
        <motion.div style={{ width: sigWidth, opacity: opacityLogo }}>
          <SignatureLogo />
        </motion.div>
        <motion.div style={{ width: sigWidth, opacity }}>
          <Signature />
        </motion.div>
      </div>
      <motion.div
        style={{ opacity }}
        className={classes["page-down-indicator"]}
      >
        <FaAnglesDown id={"page-indicator"} />
      </motion.div>
      <div className={classes["section"]}>
        <div>
          <SectionHeader title={"About Me"} />
          <About />
        </div>
      </div>
      <div className={classes["section"]}>
        <div>
          <SectionDivider />
          <SectionHeader title={"Experience"} />
          <Experience />
        </div>
      </div>
      <div className={classes["section"]}>
        <div>
          <SectionDivider />
          <SectionHeader title={"Education"} />
          <Education />
        </div>
      </div>
      <div className={classes["section"]}>
        <div>
          <SectionDivider />
          <SectionHeader title={"Skills"} />
          <Skills />
        </div>
      </div>
      <div className={classes["section"]}>
        <div>
          <SectionDivider />
          <SectionHeader title={"Projects"} />
          <Projects />
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
