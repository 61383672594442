import { MantineTheme } from "@mantine/core";

export type IMantineColor = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

export const useTogglableTheme = (isDark: boolean, theme: MantineTheme) => {
  return {
    text: isDark ? "#fff" : theme.colors.dark[6],
    card_bg: isDark ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.04)",
    card_disabled_bg: isDark ? "rgba(200,200,200,0.4)" : "rgba(0,0,0,0.12)",
    card_disabled_text: isDark ? theme.colors.dark[0] : theme.colors.dark[3],
    primary: theme.colors[theme.primaryColor][6],
    primaryText: isDark
      ? theme.colors[theme.primaryColor][6]
      : theme.colors[theme.primaryColor][7],
  };
};

// generate lightest color on start and darkest color on end and baseHexcode in center
export function generateShadesTintsColors(baseHexcode: string): IMantineColor {
  const baseColor: string = baseHexcode.replace(/^#/, "");
  const r: number = parseInt(baseColor.slice(0, 2), 16);
  const g: number = parseInt(baseColor.slice(2, 4), 16);
  const b: number = parseInt(baseColor.slice(4, 6), 16);

  let baseBrightness = r * 0.299 + g * 0.587 + b * 0.114;

  let tintStep = baseBrightness / 7;
  let shadeStep = 255 - baseBrightness / 4;

  const shadesTints: string[] = [];
  // Generate lighter shades/tints on the left side of the base color (arr[0] to arr[5])
  for (let i: number = 6; i >= 1; i--) {
    const newR: number = Math.floor(
      Math.min(255, Math.max(0, r + i * Math.min(tintStep, shadeStep)))
    );
    const newG: number = Math.floor(
      Math.min(255, Math.max(0, g + i * Math.min(tintStep, shadeStep)))
    );
    const newB: number = Math.floor(
      Math.min(255, Math.max(0, b + i * Math.min(tintStep, shadeStep)))
    );

    const newHex: string = `#${newR.toString(16).padStart(2, "0")}${newG
      .toString(16)
      .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
    shadesTints.push(newHex);
  }
  // Insert the base color in the center (arr[5])
  shadesTints.push(baseHexcode);
  // Generate darker shades/tints on the right side of the base color (arr[7] to arr[10])
  for (let i: number = 1; i <= 4; i++) {
    const newR: number = Math.floor(
      Math.max(0, r - i * Math.min(tintStep, shadeStep))
    );
    const newG: number = Math.floor(
      Math.max(0, g - i * Math.min(tintStep, shadeStep))
    );
    const newB: number = Math.floor(
      Math.max(0, b - i * Math.min(tintStep, shadeStep))
    );

    const newHex: string = `#${newR.toString(16).padStart(2, "0")}${newG
      .toString(16)
      .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
    shadesTints.push(newHex);
  }
  return shadesTints as IMantineColor;
}

export function generateTextColor(hex: string, bw: boolean): string {
  if (!hex) {
    return "#000000";
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r: string | number = parseInt(hex.slice(0, 2), 16),
    g: string | number = parseInt(hex.slice(2, 4), 16),
    b: string | number = parseInt(hex.slice(4, 6), 16);

  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 140 ? "#000000" : "#FFFFFF";
  }

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return

  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function getColorScheme(hex: string): "light" | "dark" {
  if (!hex) {
    return "light";
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r: string | number = parseInt(hex.slice(0, 2), 16),
    g: string | number = parseInt(hex.slice(2, 4), 16),
    b: string | number = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 140 ? "light" : "dark";
}

export function generateTextMantineColor(
  bgColorSet: IMantineColor,
  bw: boolean = true
): IMantineColor {
  return bgColorSet.map((bgColor) =>
    generateTextColor(bgColor, bw)
  ) as IMantineColor;
}

export function padZero(str: string, len?: number) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
