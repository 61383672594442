import { Center } from "@mantine/core";
import { signature } from "src/assets/index";
import anime from "animejs";
import classes from "./Signature.module.css";
import { useCallback, useEffect } from "react";

type Props = {
  skipAnim?: boolean;
  repeat?: boolean;
};

const Signature = ({ skipAnim = false, repeat = false }: Props) => {
  const animateSignature = useCallback(() => {
    anime({
      targets: ".signature-path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInSine",
      duration: 3000,
      direction: repeat ? "alternate" : "forward",
      loop: repeat,
    });
  }, [repeat]);

  useEffect(() => {
    if (!skipAnim) {
      animateSignature();
    }
  }, [skipAnim, animateSignature]);

  return <Center className={classes["signature-wrapper"]}>{signature}</Center>;
};

export default Signature;
