import { useContext, memo } from "react";
import {
  DefaultMantineColor,
  AppShell,
  Tabs,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { AppContext } from "src/api/AppContext";
// import { UserRole } from "src/generated/graphql";
import { TAB_LIST } from "src/utils/constants";
import { BiHomeAlt2 } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";

interface NavbarProps {}

type TabType = {
  key: string;
  label: string;
  // roles?: UserRole[];
  icon?: React.ReactNode;
  color?: DefaultMantineColor;
  elementOverride?: React.ReactNode;
};

const Navbar: React.FC<NavbarProps> = (props: NavbarProps) => {
  const { changeTab, activeTabKey } = useContext(AppContext);
  const theme = useMantineTheme();

  const Tablist: TabType[] = [
    {
      key: TAB_LIST.HOME,
      label: TAB_LIST.HOME,
      icon: <BiHomeAlt2 size={17} color={theme.colors.secondaryText[6]} />,
    },
  ];

  return (
    <AppShell.Navbar>
      <Tabs
        orientation="vertical"
        value={activeTabKey}
        onChange={(key) => {
          changeTab(key || "");
        }}
      >
        <Tabs.List
          style={{
            flex: 1,
            borderColor: theme.colors.primary[6],
            border: "none",
          }}
          grow
        >
          {Tablist.map((tab) => {
            return (
              <Tabs.Tab
                key={tab.key}
                value={tab.key}
                leftSection={tab.icon}
                c={tab.color}
                bg={theme.colors.secondary[6]}
                rightSection={
                  <IoIosArrowForward
                    size={15}
                    style={{
                      position: "absolute",
                      right: 4,
                      color: theme.colors.secondaryText[6],
                    }}
                  />
                }
                style={{
                  backgroundColor:
                    activeTabKey === tab.key
                      ? theme.colors.primary[6]
                      : theme.colors.secondary[6],
                  "&:hover": {
                    backgroundColor:
                      activeTabKey === tab.key
                        ? theme.colors.primary[4]
                        : theme.colors.secondary[4],
                  },
                  borderBottom: theme.colors.secondaryText[6],
                  padding: "14px 10px 10px 10px",
                  ...{
                    borderRadius: 0,
                  },
                  ...(activeTabKey === tab.key
                    ? {
                        borderRadius: 4,
                        backgroundColor: theme.colors.primary[6],
                        fontWeight: 500,
                        color: theme.colors.primaryText[6],
                      }
                    : {}),
                }}
              >
                <Text
                  size={"md"}
                  fw={500}
                  pl={6}
                  c={theme.colors.secondaryText[6]}
                >
                  {tab.label}
                </Text>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>
      </Tabs>
    </AppShell.Navbar>
  );
};

export default memo(Navbar);
