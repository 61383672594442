export const ROUTES = {
  HOME: "/home",
};

// No Auth Routes
export const PUBLIC_ROUTES = {
  HOME: `Home`,
};

export const ROUTE_NAMES = {};

export const TAB_LIST = {
  HOME: "Home",
};

export const weeksArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function ordinal_suffix_of(i: number) {
  let suffix = "th";
  if (i < 4 || i > 20) {
    let lastDigit = i % 10;
    if (lastDigit === 1) {
      suffix = "st";
    } else if (lastDigit === 2) {
      suffix = "nd";
    } else if (lastDigit === 3) {
      suffix = "rd";
    }
  }
  return i + suffix;
}
export const daysArray = Array(28)
  .fill(0)
  .map((_, i) => ordinal_suffix_of(i + 1));

export const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const enum HeightCard {
  xs = 30,
  sm = 34,
  md = 40,
  lg = 44,
}

export const enum CardRadius {
  xs = 4,
  sm = 6,
  md = 8,
  lg = 12,
}

export enum MeetingTypes {
  Waitlistee = "Waitlistee",
  Attendee = "Attendee",
}

export function generateUniqueColors(
  count: number,
  target?: number,
  sigma?: number
) {
  const colors = [];
  if (target && sigma) {
    const hueStep = sigma / count;

    let offset = Math.floor(-count / 2);
    for (let i = 0; i < count; i++) {
      const hue = (i + offset) * hueStep + target;
      const color = `hsl(${hue}, 78%, 60%)`;
      colors.push(color);
    }
  } else {
    const hueStep = 360 / count;

    for (let i = 0; i < count; i++) {
      const hue = (i * hueStep) % 360;
      const color = `hsl(${hue}, 90%, 35%)`;
      colors.push(color);
    }
  }

  return colors;
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const defaultPagination = {
  filters: [],
};

export function groupByDate<T>(
  data: T[],
  getDate: (item: T) => string,
  sort?: "ASC" | "DSC"
): T[][] {
  const groupedMap = new Map<string, T[]>();

  data.forEach((item) => {
    const dateWithoutTime = new Date(getDate(item)).toLocaleDateString();
    if (!groupedMap.has(dateWithoutTime)) {
      groupedMap.set(dateWithoutTime, []);
    }
    groupedMap.get(dateWithoutTime)?.push(item);
  });

  let sortedGroups = Array.from(groupedMap.entries());
  if (sort) {
    return sortedGroups
      .sort(([dateA], [dateB]) =>
        sort === "ASC"
          ? new Date(dateA).getTime() - new Date(dateB).getTime()
          : new Date(dateB).getTime() - new Date(dateA).getTime()
      )
      .map(([_, group]) => group);
  } else {
    return sortedGroups.map(([_, group]) => group);
  }
}

export const enum ModalId {}

export const enum CRUDSTATES {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export const TIMEZONES = [
  { name: "Alaska Time", value: "America/Anchorage" },
  { name: "Central Time", value: "America/Chicago" },
  { name: "Eastern Time", value: "America/New_York" },
  { name: "Hawaii Time", value: "Pacific/Honolulu" },
  { name: "Mountain Time", value: "America/Denver" },
  { name: "Pacific Time", value: "America/Los_Angeles" },
];

export const Hours = [
  { name: "12 AM", value: "0" },
  { name: "1 AM", value: "1" },
  { name: "2 AM", value: "2" },
  { name: "3 AM", value: "3" },
  { name: "4 AM", value: "4" },
  { name: "5 AM", value: "5" },
  { name: "6 AM", value: "6" },
  { name: "7 AM", value: "7" },
  { name: "8 AM", value: "8" },
  { name: "9 AM", value: "9" },
  { name: "10 AM", value: "10" },
  { name: "11 AM", value: "11" },
  { name: "12 PM", value: "12" },
  { name: "1 PM", value: "13" },
  { name: "2 PM", value: "14" },
  { name: "3 PM", value: "15" },
  { name: "4 PM", value: "16" },
  { name: "5 PM", value: "17" },
  { name: "6 PM", value: "18" },
  { name: "7 PM", value: "19" },
  { name: "8 PM", value: "20" },
  { name: "9 PM", value: "21" },
  { name: "10 PM", value: "22" },
  { name: "11 PM", value: "23" },
];

export const MigrationModalStyle = {
  styles: {
    modal: {
      width: "auto",
    },
  },
};

export const fullScreenModalCss = {
  body: {
    padding: 0,
  },
  root: {
    marginTop: 58,
    zIndex: 99,
  },
  header: {
    display: "none",
  },
};
