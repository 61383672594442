import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import classes from "./Projects.module.css";
import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { useCallback } from "react";
import {
  PROJECT_SLIDE_CONTENT_MEDICAL,
  PROJECT_SLIDE_TECH_MEDICAL,
} from "./content";

const Projects = () => {
  const mobile = useMediaQuery("(max-width: 46em");
  const TRANSITION_DURATION = 200;

  const [embla, setEmbla] = useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const { width: viewPortWidth } = useViewportSize();

  const wordWidths = {} as { [word: string]: number };

  const xmlsn = "http://www.w3.org/2000/svg";

  const svg = document.createElementNS(xmlsn, "svg");
  const text = document.createElementNS(xmlsn, "text");
  const spaceText = document.createElementNS(xmlsn, "text");
  svg.appendChild(text);
  svg.appendChild(spaceText);

  const styleString = (object: any) => {
    return Object.keys(object).reduce((prev, curr) => {
      return `${(prev += curr
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase())}:${object[curr]};`;
    }, "");
  };

  const getWordWidth = (character: string, style: any) => {
    const cachedWidth = wordWidths[character];
    if (cachedWidth) return cachedWidth;

    let width;

    if (character === " ") {
      const textNode = document.createTextNode("t t");
      spaceText.appendChild(textNode);
      spaceText.setAttribute("style", styleString(style));
      width = spaceText.getBoundingClientRect().width;
      width -= 2 * getWordWidth("t", style);
      wordWidths[" "] = width;
      spaceText.removeChild(textNode);
    } else {
      const textNode = document.createTextNode(character);
      text.appendChild(textNode);
      text.setAttribute("style", styleString(style));
      width = text.getBoundingClientRect().width;
      wordWidths[character] = width;
      text.removeChild(textNode);
    }

    return width;
  };

  const getNumberOfLines = (text: string, maxWidth: number, style: any) => {
    let numberOfLines = 1;

    const words = text.replace(/\s+/g, " ").trim().split(" ");
    const spaceWidth = getWordWidth(" ", style);

    let lineWidth = 0;
    const wordsLength = words.length;

    for (let i = 0; i < wordsLength; i++) {
      const wordWidth = getWordWidth(words[i], style);

      if (lineWidth + wordWidth > maxWidth) {
        if (lineWidth !== 0) {
          numberOfLines += 1;
        }

        lineWidth = wordWidth + spaceWidth;
        continue;
      }

      lineWidth += wordWidth + spaceWidth;
    }

    return numberOfLines;
  };

  const getMobileHeight = useCallback(() => {
    document.body.appendChild(svg);

    let width = viewPortWidth * 0.8 - 19.2 * 1.4;
    let lineheight = 14 * 1.55;
    let contentTitleH = 52;
    let techTitleH = 36;
    let paddingAndBottomGap = 19.2 * 1.4 + 14;

    let contentLines = getNumberOfLines(PROJECT_SLIDE_CONTENT_MEDICAL, width, {
      opacity: 0,
    });
    let techLines = getNumberOfLines(PROJECT_SLIDE_TECH_MEDICAL, width, {
      opacity: 0,
    });

    document.body.removeChild(svg);

    return (
      ((contentLines + techLines) * lineheight +
        contentTitleH +
        techTitleH +
        paddingAndBottomGap) /
      0.8
    );
  }, [mobile, viewPortWidth]);

  return (
    <div>
      <div className={classes["projects-disclaimer"]}>
        The projects below were completed as private contract work and are
        anonymized with pertinent details removed in accordance with NDA
        requirements.
      </div>
      <Carousel
        classNames={classes}
        slideSize={mobile ? "100%" : "70%"}
        height={mobile ? `${getMobileHeight()}px` : "70vh"}
        slideGap="md"
        slidesToScroll={1}
        withIndicators
        nextControlIcon={mobile && <FaChevronRight />}
        previousControlIcon={mobile && <FaChevronLeft />}
        loop
        getEmblaApi={setEmbla}
      >
        <Carousel.Slide>
          <div>
            <div className="slide-header">
              <div className={classes["project-img-gym"]} />
            </div>
            <div className="slide-body">
              <div>
                <div className="slide-body-title">Club Management App</div>
                <div className="slide-body-content">
                  This project involved developing software for a SaaS company
                  that provides customizable complete solution tools for manging, promoting, and operating gyms and fitness franchises
                  nationwide. The software features a robust booking system,
                  advanced retail and subscription payment processing,
                  attendance tracking, and in-depth reporting. These tools were
                  designed to scale seamlessly from single locations to national
                  franchises, ensuring flexibility and comprehensive support for
                  gym management.
                </div>
              </div>
              <div className="slide-body-footer">
                <div className="slide-footer-title">Tech</div>
                <div className="slide-footer-content">
                  Node {"\u00B7 "}
                  React {"\u00B7 "}
                  Express {"\u00B7 "}
                  GraphQL {"\u00B7 "}
                  TypeORM {"\u00B7 "}
                  SQL Server {"\u00B7 "}
                  Twillio {"\u00B7 "}
                  Payroc {"\u00B7 "}
                  SignNow {"\u00B7 "}
                  AWS {"\u00B7 "}
                  Azure Cloud
                </div>
              </div>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div>
            <div className="slide-header">
              <div className={classes["project-img-invest"]} />
            </div>
            <div className="slide-body">
              <div>
                <div className="slide-body-title">Investment Portfolio App</div>
                <div className="slide-body-content">
                  For this client, I developed an application that provides a
                  virtual environment for small-scale investors to manage and
                  track their investment portfolios while connecting them to
                  expert investment analysts. This project honed my expertise in
                  fintech, enhancing my skills in developing secure and
                  efficient financial management tools tailored to the needs of
                  individual investors.
                </div>
              </div>
              <div className="slide-body-footer">
                <div className="slide-footer-title">Tech</div>
                <div className="slide-footer-content">
                  Node {"\u00B7 "}
                  React {"\u00B7 "}
                  Express {"\u00B7 "}
                  GraphQL {"\u00B7 "}
                  TypeORM {"\u00B7 "}
                  SQL Server {"\u00B7 "}
                  Twillio {"\u00B7 "}
                  Stripe {"\u00B7 "}
                  FMP Cloud {"\u00B7 "}
                  Plaid {"\u00B7 "}
                  AWS {"\u00B7 "}
                  Azure Cloud
                </div>
              </div>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div>
            <div className="slide-header">
              <div className={classes["project-img-wellness"]} />
            </div>
            <div className="slide-body">
              <div>
                <div className="slide-body-title">Personal Wellness App</div>
                <div className="slide-body-content">
                  This project involved developing a subscription-based mental
                  wellness application designed to help users develop healthy
                  coping mechanisms, conduct regular mental health checkups, and
                  overcome trauma. The project included a web app for
                  psychiatrists to create curricula and a native mobile app
                  where subscribers could access generalized material, tailored
                  programs, questionnaires, and interactive simulations.
                </div>
              </div>
              <div className="slide-body-footer">
                <div className="slide-footer-title">Tech</div>
                <div className="slide-footer-content">
                  Node {"\u00B7 "}
                  React {"\u00B7 "}
                  React-Native {"\u00B7 "}
                  Redux {"\u00B7 "}
                  GraphQL {"\u00B7 "}
                  TypeORM {"\u00B7 "}
                  SQL Server {"\u00B7 "}
                  Firebase {"\u00B7 "}
                  Stripe {"\u00B7 "}
                  AWS {"\u00B7 "}
                  Azure Cloud
                </div>
              </div>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div>
            <div className="slide-header">
              <div className={classes["project-img-medical"]} />
            </div>
            <div className="slide-body">
              <div>
                <div className="slide-body-title">Medical Care App</div>
                <div className="slide-body-content">
                  This med space project served as both an interface and conduit
                  between medical professionals, healthcare consumers, insurance
                  claim reviewers, and cloud-based medical records. It pushed
                  the boundary of our technological capabilities, encompassing
                  13 applications and micro-services to provide comprehensive
                  coverage of requirements. The project involved rigorous
                  critique to ensure compliance with local, state, and federal
                  policies and regulations concerning medical documents and
                  information transfer. The initial implementation in several
                  urgent care facilities has yielded positive results.
                </div>
              </div>
              <div className="slide-body-footer">
                <div className="slide-footer-title">Tech</div>
                <div className="slide-footer-content">
                  Node {"\u00B7 "}
                  React {"\u00B7 "}
                  React-Native {"\u00B7 "}
                  Redux {"\u00B7 "}
                  Prisma {"\u00B7 "}
                  Nexus {"\u00B7 "}
                  MongoDB {"\u00B7 "}
                  C# {"\u00B7 "}
                  .NET {"\u00B7 "}
                  SQL Server {"\u00B7 "}
                  Sendgrid {"\u00B7 "}
                  Firebase {"\u00B7 "}
                  Twilio {"\u00B7 "}
                  Gravity {"\u00B7 "}
                  HL7 FHIR {"\u00B7 "}
                  AWS {"\u00B7 "}
                  Azure Cloud
                </div>
              </div>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div>
            <div className="slide-header">
              <div className={classes["project-img-videogame"]} />
            </div>
            <div className="slide-body">
              <div>
                <div className="slide-body-title">Personal Projects</div>
                <div className="slide-body-content">
                  In my free time, I enjoy working on personal projects that
                  range from home automation applications to digital toolsets.
                  However, most of my programming efforts outside of work are
                  dedicated to game development, a passion that sparked my
                  interest in software development from a young age. While these
                  skills may not directly translate into my everyday work, they
                  provide a valuable creative outlet while continuing to hone my
                  professional craft.
                </div>
              </div>
              <div className="slide-body-footer">
                <div className="slide-footer-title">Tech</div>
                <div className="slide-footer-content">
                  C++ {"\u00B7 "}
                  C# {"\u00B7 "}
                  Python {"\u00B7 "}
                  JS/TS {"\u00B7 "}
                  Java {"\u00B7 "}
                  Lua {"\u00B7 "}
                  SQL {"\u00B7 "}
                  Mongo {"\u00B7 "}
                  Unreal Engine 5 {"\u00B7 "}
                  Unity {"\u00B7 "}
                  OpenGL & WebGL {"\u00B7 "}
                  Blender3D {"\u00B7 "}
                  Photoshop {"\u00B7 "}
                  Paint.net {"\u00B7 "}
                  Abelton Live {"\u00B7 "}
                  FL Studio {"\u00B7 "}
                  AWS Cloud
                </div>
              </div>
            </div>
          </div>
        </Carousel.Slide>
      </Carousel>
    </div>
  );
};

export default Projects;
