import React, { FC } from "react";
import {
  AppShell as MantineAppShell,
  MantinePrimaryShade,
  useMantineColorScheme,
} from "@mantine/core";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import { useDisclosure } from "@mantine/hooks";
import Footer from "./components/Footer";

interface Props {
  children: React.ReactNode;
}

const AppShell: FC<Props> = ({ children }) => {
  const navbarBreakpoint = "md";
  const { colorScheme } = useMantineColorScheme();

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);
  return (
    <MantineAppShell
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colors.secondary[
              colorScheme === "light"
                ? (theme.primaryShade as MantinePrimaryShade).light
                : (theme.primaryShade as MantinePrimaryShade).dark
            ],
          width: "100%",
          fontFamily: '"Work Sans", sans-serif',
          fontOpticalSizing: "auto",
          fontWeight: 400,
          fontStyle: "normal",
        },
      })}
      header={{ height: 75, collapsed: false, offset: false }}
      navbar={{
        width: 220,
        breakpoint: navbarBreakpoint,
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
    >
      <Header
        mobileOpened={mobileOpened}
        desktopOpened={desktopOpened}
        toggleMobile={toggleMobile}
        toggleDesktop={toggleDesktop}
        navbarBreakpoint={navbarBreakpoint}
      />
      <Navbar />
      <MantineAppShell.Main>
        {children}
        <Footer />
      </MantineAppShell.Main>
    </MantineAppShell>
  );
};

export default AppShell;

interface Props {
  children: React.ReactNode;
}
