import classes from "./SectionHeader.module.css";

type SectionHeaderProps = {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <div
      className={classes["header-wrapper"]}
      onClick={(e) => props.onClick && props.onClick(e)}
    >
      <div className={classes["header-text"]}>{props.title}</div>
    </div>
  );
};

export default SectionHeader;
