import classes from "./Footer.module.css";
import { FaLinkedin } from "react-icons/fa";

const linkedInURI = "https://www.linkedin.com/in/andrew-tanaka-5a241b1a7/";

const Footer = () => {
  return (
    <div className={classes["footer-wrapper"]}>
      <div className={classes.banner}>
        <div className={classes["banner-text"]}>
          Andrew Tanaka {"\u00A9 2024"}
        </div>
        <FaLinkedin
          className={classes["banner-icon"]}
          onClick={() => window.open(linkedInURI, "_blank")}
        />
      </div>
    </div>
  );
};

export default Footer;
