import { Collapse, Group, Text, Timeline } from "@mantine/core";
import { useState } from "react";
import classes from "./Experience.module.css";
import PlusMinusButton from "src/components/PlusMinusButton";
import { useMediaQuery } from "@mantine/hooks";

type Props = {
  alwaysOpen?: boolean;
};

const Experience = ({ alwaysOpen }: Props) => {
  const [target, setTarget] = useState<number | undefined>();

  const mobile = useMediaQuery("(max-width: 46em");

  const toggle = (item: number) => {
    if (target === item) {
      setTarget(undefined);
    } else {
      setTarget(item);
    }
  };

  return (
    <div className={classes["Experience-wrapper"]}>
      <Timeline className={classes.timeline} bulletSize={"3em"} lineWidth={4}>
        <Timeline.Item
          title={
            <Group
              align="flex-start"
              gap={"sm"}
              justify="space-between"
              onClick={() => toggle(0)}
            >
              <Text>{"Software Engineer: Hybrid Role - Manager & Lead"}</Text>
              {!alwaysOpen && !mobile && (
                <PlusMinusButton
                  active={target === 0}
                  onToggle={(value) =>
                    value ? setTarget(0) : setTarget(undefined)
                  }
                />
              )}
            </Group>
          }
          bullet={
            <div className={classes["experience-icon-kapex"]}>
              <div />
            </div>
          }
        >
          <Text
            className={classes.subtext}
            size="sm"
          >{`Kapex Technology | Feb 2022 - June 2024`}</Text>
          <Collapse
            in={mobile || alwaysOpen || target === 0}
            className={classes["collapse-section"]}
          >
            <div>
              <div>
                {`On the engineering management side of my role at Kapex, I managed multiple agile development teams, overseeing the delivery of custom software solutions and integrations across diverse industries. Collaborating closely with stakeholders, I scoped features, planed architecture, and estimated resources and costs to draft detailed statements of work. I met regularly with clients, their SMEs, and third-party software solutions on my clients' behalf to ensure the continued success, accuracy, and timeliness of the delivered product. I enjoyed a healthy 40/60 split of managerial work and software development contribution during my time at Kapex.`}
              </div>
              <div>
                {`The technical portion of my position at Kapex involved architecting APIs & databases and ensuring the security and efficiency of their network provisions. Other tasks included developing pipelines and leveraging cloud resources to provide flexible scalability, which in some cases led to significant operating cost reductions. Additionally, I provided senior-level support by reviewing code contributions, resolving technical impediments, and shielding teams from external interference.`}
              </div>
              <div>
                {`All aspects of my role revolved around maintaining efficient, transparent, and approachable communication and management styles. This involved conducting scrum ceremonies and one-on-one sessions to analyze strengths, address concerns, and facilitate cohesion and professional development among direct reports. I view fostering cohesion, removing logistical barriers, and empowering team members to be self-driven, team-oriented contributors as cornerstones of my approach to building strong teams. I am commited to establishing trust, unity, and respect across all of my teams through exemplary action. One such instance where I demonstrated that commitment was when I adjusted my working day by 12 hours to provide continuous support and direction during the working hours of a newly established overseas team that was struggling to establish healthy collaborative and communicative practices.`}
              </div>
            </div>
          </Collapse>
        </Timeline.Item>
        <Timeline.Item
          title={
            <Group
              align="flex-start"
              gap={"sm"}
              justify="space-between"
              onClick={() => toggle(1)}
            >
              <Text>{"Software Engineer"}</Text>
              {!alwaysOpen && !mobile && (
                <PlusMinusButton
                  active={target === 1}
                  onToggle={(value) =>
                    value ? setTarget(1) : setTarget(undefined)
                  }
                />
              )}
            </Group>
          }
          bullet={
            <div className={classes["experience-icon-truecare"]}>
              <div />
            </div>
          }
        >
          <Text
            className={classes.subtext}
            size="sm"
          >{`TrueCare | Jul 2020 - Feb 2022`}</Text>
          <Collapse
            in={mobile || alwaysOpen || target === 1}
            className={classes["collapse-section"]}
          >
            <div>
              <div>
                {`At TrueCare, I implemented web, Android, and iOS applications for the healthcare industry, catering individually to providers, patients, and insurance claims agents. My responsibilities included maintaining a wide array of applications and micro-services using technologies such as Postgres, SQL Server, React, ReactNative, Node.js, C#, and .NET, to ensure they met the needs of our diverse end-user groups.`}
              </div>
              <div>
                {`During my time at TrueCare I served the team as the React/GraphQL expert and supported the team through demonstrational seminars and comprehensive logic, design, and best-practice resources, a practice I later adopted into my managerial approach. One of my key contributions involved leading the migration of several of our environments to a functional React, TypeScript-based approach. This transition improved the long-term support and scalability of our applications, ensuring they remained robust and adaptable to future needs.`}
              </div>
            </div>
          </Collapse>
        </Timeline.Item>
        <Timeline.Item
          title={
            <Group
              align="flex-start"
              gap={"sm"}
              justify="space-between"
              onClick={() => toggle(2)}
            >
              <Text>{"Lead Software Developer"}</Text>
              {!alwaysOpen && !mobile && (
                <PlusMinusButton
                  active={target === 2}
                  onToggle={(value) =>
                    value ? setTarget(2) : setTarget(undefined)
                  }
                />
              )}
            </Group>
          }
          bullet={
            <div className={classes["experience-icon-unc"]}>
              <div />
            </div>
          }
        >
          <Text
            className={classes.subtext}
            size="sm"
          >{`UNC-CH Educational Software Reserach Lab | Nov 2016 - Sep 2018`}</Text>
          <Collapse
            in={mobile || alwaysOpen || target === 2}
            className={classes["collapse-section"]}
          >
            <div>
              <div>
                {`At UNC-Chapel Hill, I led the recruitment, hiring, and training of junior software developers, providing agile methodology seminars, code reviews, and pair programming sessions during the onboarding phase. My responsibilities included defining project requirements and identifying costs by analyzing architecture design, implementation, testing, and deployment needs.`}
              </div>
              <div>
                {`I organized an agile scrum team that implemented Test-Driven Development (TDD). The project itself involved developing single-page React applications, interactive simulations, and animations integrated with the Wise REST API for cross-institution secure storage of user interaction data. Additionally, I established resources outlining methodology and best practices to streamline the onboarding of junior devs, assist integration, and ensure team cohesion.`}
              </div>
              <div>
                {`One of the most rewarding aspects of this job was visiting the schools that had implemented our learning software. It was incredibly fulfilling to see the excitement and engagement of the students using the gamified curriculum. Ultimately, the results were better long-term and high-accuracy retention of special topics as well as improved standardized test scores in comparison with control group classes.`}
              </div>
            </div>
          </Collapse>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default Experience;
