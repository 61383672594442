import { Stack, Text } from "@mantine/core";
import classes from "./SkillGroup.module.css";
import SkillRating from "../SkillRating";

export type SkillListItem = { name: string; rating: number };

type Props = {
  groupLabel: string;
  skills: SkillListItem[];
};

const SkillGroup = ({ groupLabel, skills }: Props) => {
  return (
    <Stack className={classes["group-wrapper"]}>
      <Text className={classes["group-label"]}>{groupLabel}</Text>
      {skills.map((skill, index) => (
        <SkillRating
          key={`skill-item-${index}-${skill.name}`}
          name={skill.name}
          value={skill.rating}
        />
      ))}
    </Stack>
  );
};

export default SkillGroup;
