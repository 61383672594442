import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { EnvConfig } from "src/utils/EnvConfig";
import { ROUTES, TAB_LIST } from "src/utils/constants";

export type AppContextData = {
  env: EnvConfig | undefined;
  activeTabKey: string;
  currentPageTitle: string | undefined;
  isDarkMode: boolean;
  workspaceDimensions: { width: number; height: number } | undefined;
  changeTab: (key: string, id?: string) => void;
  // refetchUserData: () => void;
  setCurrentPageTitle: Dispatch<
    SetStateAction<AppContextData["currentPageTitle"]>
  >;
  setIsDarkMode: Dispatch<SetStateAction<AppContextData["isDarkMode"]>>;
  setWorkspaceDimensions: Dispatch<
    SetStateAction<AppContextData["workspaceDimensions"]>
  >;
  animTrigger: boolean | undefined;
  reloadAnims: () => void;
};
export const AppContext = createContext<AppContextData>({} as AppContextData);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};

interface Props {
  env: EnvConfig | undefined;
  children: React.ReactNode;
}

const AppProvider: FC<Props> = ({ children, env }) => {
  const navigate = useNavigate();

  const [animTrigger, setAnimTrigger] =
    useState<AppContextData["animTrigger"]>(undefined);

  const [isDarkMode, setIsDarkMode] =
    useState<AppContextData["isDarkMode"]>(false);
  const [activeTabKey, setActiveTabKey] =
    useState<AppContextData["activeTabKey"]>("Organization");
  const [currentPageTitle, setCurrentPageTitle] =
    useState<AppContextData["currentPageTitle"]>(undefined);
  const [workspaceDimensions, setWorkspaceDimensions] =
    useState<AppContextData["workspaceDimensions"]>(undefined);

  let changeArrTab = {
    [TAB_LIST.HOME]: { link: ROUTES.HOME },
  };

  const reloadAnims = () => {
    setAnimTrigger(true);
  };

  const changeTab = (key: string, id?: string) => {
    setActiveTabKey(key);
    if (!changeArrTab[key]) {
      console.log("Changed to unhandled tab");
    } else {
      navigate(changeArrTab[key]?.link);
    }
  };

  useEffect(() => {
    if (animTrigger) {
      setAnimTrigger(undefined);
    }
  }, [animTrigger]);

  return (
    <AppContext.Provider
      value={{
        activeTabKey,
        currentPageTitle,
        env,
        isDarkMode,
        reloadAnims,
        animTrigger,
        workspaceDimensions,
        changeTab,
        setCurrentPageTitle,
        setIsDarkMode,
        setWorkspaceDimensions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
